.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 0;
  line-height: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: opacity 0.3s ease;
}

.slick-prev {
  left: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.slick-next {
  right: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.8;
}

#IMG {
  height: 90vh;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
  }

  .slick-prev {
    left: 10px;
    background-size: 20px 20px;
  }

  #IMG {
    max-height: 30vh;
    width: 100%;
    object-fit: cover;
  }

  .slick-next {
    right: 10px;
    background-size: 20px 20px;
  }
}
