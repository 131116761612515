.footer {
  background-color: black;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
}

.icon-text {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  color: white;
}

.icon-text p {
  margin-left: 0.5rem;
}

.p {
  font-size: 0.8rem;
  margin-top: 0px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }

  .column {
    margin: 1rem 0;
    text-align: center;
  }

  .icon-text {
    justify-content: center;
  }

  .p {
    font-size: 1rem;
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .icon-text {
    flex-wrap: wrap;
    justify-content: center;
  }

  .icon-text p {
    margin-top: 0.5rem;
    text-align: center;
  }

  .p {
    font-size: 0.8rem;
    margin-top: 0px;
  }
}
