#gallery{
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: full;
  overflow-y: auto;
  margin-bottom: 20p;
  padding-left: 5px;
  padding-right: 5px;
}

.event-img{
  border: 1px solid black;
  padding: 5px;
  width: 350px;
}

@media (max-width: 768px) {
  .h2{
    font-size: 20px;
  }
  .eventsDetails{
    font-size: 12px;
  }
}