@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0px 30px 30px -20px;
  /*box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}
#text {
  width: 55px;
  height: 50px
}

.navDIv{
  display: inline-flex;
  align-items: center;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
}

.nav-links li {
  color: black;
  font-size: 20px;
  padding: 10px 10px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.hero {
  display: flex;
  height: 90vh;
  text-align: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}

@media (max-width: 768px) {

  .navDIv{
    display: inline-flex;
  
    align-items: center;
  }

 #text {
    width: 30px;
    height: 30px;
    top: 23px;
  }

  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    border: 1px solid #777777;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    right: 2%;
    top: 80%;
    transition: all 1s ease-in-out;
    width: 35%;
    background-color: white;
  }
  ul li {
    padding: 16px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  .hero h1 {
    font-size: 50px;
  }
}


