.cards {
  padding: 4rem;
  background-color: #fff;
}
h1 {
  text-align: center;
}
.cards__container {
  display: flex;
  align-items: center;
}
.cards__wrapper {
  position: relative;
  margin: 30px 0 25px;
}
.cards__items {
  width: 400px;
  height: 450px;
  margin-bottom: 24px;
  height: 480px;
}

/* here we are adding the css of Crad-item content */
.card__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  flex-direction: column;
  background-color: whitesmoke;
  overflow: hidden;
}
.card__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0, 6px, 20px, rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0, 6px, 20 rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}
.Details{
  height: 132px;
  width: 100%;
  display: flex;
flex-direction: column;
}

.card__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* overflow: hidden; */
}
.fade-image {
  animation-name: fade-img;
  animation-duration: 2s;
}
.card__item__pic-wrap::after {
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%)-60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}
.card_item_image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}
.card_item_image:hover {
  transform: scale(1.1);
}

.h2 {
  color: #161b3e;
  font-size: 32px;
  font-family: anton, sans-serif;
  margin: 0 0 5px;
}

.card_item_text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

.contact {
  display: flex;
  height: 75vh;
  text-align: center;
  flex-direction: column;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.font {
  font-size: 40px;
}

/* added display and flex direction property */
@media only screen and (min-width: 1200px) {
  .container {
    width: 84%;
  }
}
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .cards__item {
    margin-bottom: 2rem;
  }
  .card__item{
    margin: 0;
  }
  .cardCont{
    display: flex;
    flex-direction: column;
  }
}
