#aboutContainer{
  background-color: rgba(247, 157, 14,.4)
}
.signature {
  justify-content: end;
  display: flex;
  margin-top: 10px;
  font-size: 10px;
}


#about {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 70vh;
  
}

#dp {
  width: 350px;
  height: 400px;
}


@media (max-width: 768px) {

  #h2{
    font-size: 30px;
  }
  #dp{
    width: 200px;
    height: 200px;
  }
  .about{
    display: flex;
    flex-direction: column;
  }
  .aboutPara{
    text-align: start;
    font-size: 12px;
    letter-spacing: 1px;
  }

}