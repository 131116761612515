.event {
  display: flex;
  text-align: center;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}
.h2 {
  color: black;
  font-size: 42px;
  font-family: anton, sans-serif;
  margin: 0 0 5px;
}
.cont {
  height: 100%;
  background-color: #dddddd;
}

#cards__container1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 250px;
  overflow-x: auto;
}

#cards__wrapper {
  position: relative;
  margin: 30px 0 25px;
  height: 90%;
}
.image {
  border: 1px solid black;
  padding: 5px;
}

.pictures {
  width: 200px;
  height: 200px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

#cards__items {
  width: 400px;
  margin-bottom: 24px;
  height: 500px;
}

#Container{
  height: 75vh;
}

#ReadMore {
  position: absolute;
  bottom: -14px;
  right: 20px;
}

@media (max-width: 768px) {

  #cards__items {
    width: 400px;
    margin-bottom: 24px;
    height: 100%;
  }
  #Container{
    height: 80vh;
  }

  #cards__wrapper {
    position: relative;
    margin: 10px 0 15px;
    height: 90%;
  }
  .event {
    width: 100%;
  }
  #h2 {
    margin-left: 20px;
  }
  #cards__container1 {
    width: 100%;
  }
  .cont {
    width: 100%;
  }
  #card__item {
    padding-left: 5px;
    height: 100%;
  }
}
