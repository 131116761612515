
@tailwind base;
@tailwind components;
@tailwind utilities;

.body{
    margin: 0px;
    margin-bottom: 0px;
    color: black;
}
